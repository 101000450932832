<template>
  <div class="app-box">
    <div class="headerBox">
      <div class="setBox">
        <h4 class="titleH4">个人信息</h4>
        <van-form>
          <van-field
            v-model="infoArr.nickname"
            name="微信昵称"
            label="微信昵称"
            input-align="right"
            placeholder="微信昵称"
            readonly
          />
          <van-field
            v-model="infoArr.id_card"
            label="身份证号"
            input-align="right"
            readonly
          />
          <van-field
            v-model="infoArr.mobile"
            label="手机号码"
            input-align="right"
            readonly
          />
        </van-form>
        <!-- <div class="mari">
          <van-button type="info" block @click="goRouter('/login')">切换账号</van-button>
        </div> -->
      </div>
      <van-skeleton title  :row="3"  :loading="loading"  >
        <div class="setBox">
          <h4 class="titleH4">支付设置</h4>
          <p class="icard">我的卡</p>
          <!-- 有卡 -->
          <div class="haveCardUl" v-if="bancList.length">
            <div
              class="li"
              v-for="(item, index) in bancList"
              :key="index"
              :style="{
                'background-color': colorlists[Math.floor(Math.random() * 10)],
              }"
            >
              <div class="left">
                <img src="https://img.menghn.com/gs.png" alt="" />
              </div>
              <div class="right">
                <p>
                  {{ item.bank }}
                  <i
                    @click="deleteClick(item.id)"
                    class="iconfont icon-laji flooir"
                  ></i>
                </p>
                <p class="iconFont">储蓄卡</p>
              </div>
              <h3 class="fontite">
                **** **** ****
                {{ item.bank_num.substring(item.bank_num.length - 4) }}
              </h3>
            </div>
          </div>
          <!-- 无卡状态 -->
          <div class="bottomAdd" v-else>
            <van-button type="info" icon="add" size="large" @click="addBanc"
              >添加银行卡</van-button
            >
          </div>
        </div>
      </van-skeleton>
    </div>
    <van-popup
      v-model="identificationShow"
      :style="{ height: '40%', width: '90%' }"
      :close-on-click-overlay="false"
    >
      <van-icon
        name="cross"
        class="floatright"
        @click="identificationShow = false"
      />
      <Investment></Investment>
    </van-popup>
    <!-- 显示银行卡信息 -->
    <!-- <van-popup v-model="bacnToock" position="right" :style="{ height: '100%',width:'100%' }">
       <div class="bopoe">
         <van-icon name="cross" @click="bacnToock =false" />
         <div class="maerigt">
           <p class="titlebacn">{{bacnInfolist.bank}}</p>
           <p class="titlebacn">{{ bacnInfolist.bank_num.substring(bacnInfolist.bank_num.length - 4) }}</p>
         </div>
        </div>
    </van-popup> -->
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import {
  listscard,
  bancDelete,
  getBankCardInfo,
  realNameVerification,
} from "@/request/api";
import { mapState } from "vuex";
import Investment from "@/components/Authentication/Authentication";
export default {
  components: {
    Investment,
  },
  data() {
    return {
      loading:true,
      bankCard: "",
      bancList: [],
      identificationShow: false,
      bacnToock: false,
      colorlists: [
        "rgb(184,64,70)",
        "rgb(58,113,213)",
        "rgb(63,162,168)",
        "blueviolet",
      ],
      bacnInfolist: [],
      fordata: {
        truename: "",
        id_card: "",
      },
    };
  },
  computed: {
    ...mapState(["infoArr"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let res = await listscard();
      this.bancList = res.data;
       this.loading = false;
    },
    bacnInfo(bank_num) {
      Toast.loading({
        message: "获取中,请稍等...",
        forbidClick: true,
      });
      let data = {
        bank_num: bank_num,
      };
      getBankCardInfo(data).then((res) => {
        this.bacnInfolist = res.data;
        this.bacnToock = true;
        Toast.clear();
      });
    },
    deleteClick(id) {
      Dialog.confirm({
        title: "删除提示",
        message: "确定解除该银行卡吗？",
      })
        .then(() => {
          let data = {
            id: id,
          };
          bancDelete(data).then((res) => {
            if (res.code != 1000) {
              Toast.fail(res.message);
              return;
            }
            Toast.success("删除成功");
            this.getList();
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //判断是否认证了
    addBanc() {
      if (
        this.infoArr.id_card == null ||
        (this.infoArr.id_card == "" && this.infoArr.truename == null) ||
        this.infoArr.truename == ""
      ) {
        this.identificationShow = true;
        let path = this.$router.history.current.path;
        this.$router.push({ path, query: { redirect: "/Bank" } });
      } else {
        this.$router.push("/Bank");
      }
    },
  },
};
</script>

<style  scoped>
.app-box {
  min-height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
}
.headerBox {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
}
.setBox {
  border-bottom: 5px solid #edf2f5ff;
}
.setBox .titleH4 {
  line-height: 40px;
  padding: 5px 15px;
  box-sizing: border-box;
  font-size: 0.45rem;
}
.titleH4::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 20px;
  margin-right: 5px;
  background: #0e8affff;
  vertical-align: middle;
  border-radius: 2px;
}
.setBox .icard {
  padding: 0 15px;
  box-sizing: border-box;
}
.bottomAdd {
  padding: 0 10px;
  box-sizing: border-box;
}
.bottomAdd ::v-deep.van-button {
  border-radius: 10px;
  padding: 28px 0;
  margin: 10px 0;
}
.haveCardUl {
  width: 100%;
}
.haveCardUl .li {
  border-radius: 10px;
  overflow: hidden;
  background: #b84046ff;
  width: 95%;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
}
.haveCardUl .li .left {
  float: left;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
}
.haveCardUl .li .left img {
  width: 80%;
  margin: auto;
  margin-top: 3px;
}
.haveCardUl .li .right {
  margin-left: 40px;
  color: #fff;
  font-size: 18px;
}
.iconFont {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.fontite {
  font-size: 28px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}
.flooir {
  float: right;
}
.bopoe {
  padding: 15px;
  box-sizing: border-box;
}
.titlebacn {
  text-align: center;
  font-size: 20px;
}
.maerigt {
  margin-top: 50px;
}
.popur ::v-deep.van-popup {
  position: relative;
}
.floatright {
  position: absolute;
  top: 10px;
  right: 10px;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
.mari {
  padding: 0.2rem 0.3rem;
}
::v-deep .van-skeleton__content{
  padding: .3rem 0;
}
</style>